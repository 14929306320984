.c3-line {
  stroke-width: 2.6px !important; 
}

.c3-axis-y text {
  font-weight: bold;
}

.c3-axis-x .tick line { 
  display: none;
}

.c3-tooltip {
  color:  black
}