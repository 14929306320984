.wrapper .table{
  padding-top: 30px;
  position: relative;
}
.table .price-section{
  display: flex;
  justify-content: center;
}
.table .price-area{
  height: 150px;
  width: 150px;
  border-radius: 50%;
  padding: 2px;
}
.price-area .inner-area{
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 3px solid #fff;
  line-height: 117px;
  text-align: center;
  color: #fff;
  position: relative;
  padding-top: 10px;
}
.price-area .inner-area .text{
  font-size: 17px;
  font-weight: 600;
  margin-right: 2px;
  vertical-align: top;
}
.price-area .inner-area .price{
  font-size: 31px;
  font-weight: 600;
  vertical-align: middle;
}
.table .package-name{
  width: 100%;
  height: 2px;
  margin: 35px 0;
  margin-bottom: 18px;
  position: relative;
}
.table .package-name::before{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 25px;
  font-weight: 500;
  background: #fff;
  padding: 0 15px;
  transform: translate(-50%, -50%);
}
.table .features li{
  margin-bottom: 15px;
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.features li .list-name{
  font-size: 17px;
  font-weight: 400;
}
.features li .icon{
  font-size: 15px;
}
.table .btn button{
  width: 82%;
  margin: 0px 30px;
  height: 40px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  bottom: 30px;
}
.premium .price-area,
.premium .inner-area{
  color: #EBA521;
  border: 2px solid #EBA521
}
.premium .btn button{
  background: #EBA521;
}
.free .price-area,
.free .inner-area{
  color: #5CD29D;
  border: 2px solid #5CD29D
}
.free .btn button{
  background: #5CD29D;
}
.standard .price-area,
.standard .inner-area{
  color: #60B9EB;
  border: 2px solid #60B9EB
}
.standard .btn button{
  background: #60B9EB;
}
.premium .package-name{
  background: #EBA521;
}
.free .package-name{
  background: #5CD29D;
}
.standard .package-name{
  background: #60B9EB;
}
.premium .package-name::before{
  content: "";
  font-size: 24px;
}
.free .package-name::before{
  content: "";
  font-size: 24px;
}
.standard .package-name::before{
  content: "";
  font-size: 24px;
}
.table .ribbon{
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}
.table .ribbon::before,
.table .ribbon::before{
  top: 0px;
  right: 15px;
}
.table .ribbon::after{
  bottom: 15px;
  left: 0px;
}
.table .ribbon span{
  position: absolute;
  top: 30px;
  right: 0;
  transform: rotate(-45deg);
  width: 200px;
  background: #EBA521;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  text-transform: uppercase;
}
.table .ribbonfree{
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}
.table .ribbonfree::before,
.table .ribbonfree::after{
  position: absolute;
  content: "";
  z-index: -1;
  display: block;
  border: 7px solid #5CD29D;
  border-top-color: transparent;
  border-left-color: transparent;
}
.table .ribbonfree::before{
  top: 0px;
  right: 15px;
}
.table .ribbonfree::after{
  bottom: 15px;
  left: 0px;
}
.table .ribbonfree span{
  position: absolute;
  top: 30px;
  right: 0;
  transform: rotate(-45deg);
  width: 200px;
  background: #5CD29D;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  text-transform: uppercase;
}
.table .ribbonstandard{
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}
.table .ribbonstandard::before,
.table .ribbonstandard::after{
  position: absolute;
  content: "";
  z-index: -1;
  display: block;
  border: 7px solid #60B9EB;
  border-top-color: transparent;
  border-left-color: transparent;
}
.table .ribbonstandard::before{
  top: 0px;
  right: 15px;
}
.table .ribbonstandard::after{
  bottom: 15px;
  left: 0px;
}
.table .ribbonstandard span{
  position: absolute;
  top: 30px;
  right: 0;
  transform: rotate(-45deg);
  width: 200px;
  background: #60B9EB;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  text-transform: uppercase;
}